

































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import * as ExamProjectApi from "@/api/examProject";
import * as _ from "lodash";

@Component({
  name: "SystemManagePage",
  components: {
    cardBox,
  },
})
export default class extends Vue {
  private cleanHistoryProjectParams: {
    examStartTimeWithOutDays: number;
  } = {
    examStartTimeWithOutDays: 60,
  };

  private loadingCleanHistoryProject: boolean = false;
  private async doCleanHistoryProjectClick() {
    await this.$confirm("清除历史考试项目数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });
    this.loadingCleanHistoryProject = true;
    try {
      await ExamProjectApi.postCleanHistoryProject(
        this.cleanHistoryProjectParams,
        false
      );
      this.$message({
        type: "success",
        message: "删除成功!",
      });
    } finally {
      this.loadingCleanHistoryProject = false;
    }
  }

  private async __init() {}
  mounted() {
    this.__init();
  }
}
